<template>
  <b-row>
    <b-col md="12" class="mb-2">
      <b-card>
        <b-row>
          <b-col md="6" class="mb-md-0 mb-4">
            <h3 class="mb-3">
              {{ $t("Pages.Tools.Calculator.Title.can_earn") }}
            </h3>
            <b-form>
              <b-form-group>
                <b-row class="justify-content-between align-items-center mb-1">
                  <b-col cols="12">
                    <span>{{
                      $t("Pages.Tools.Calculator.Label.daily_views")
                    }}: </span>
                     <span style="font-size: 16px; color: #db2d36">{{
                        dailyViews
                      }}</span>
                    {{ $t("Pages.Tools.Calculator.Label.view_day") }}
                  
                  </b-col>
                </b-row>

                <!-- //////range 1 daily view///// -->
                <div class="progress-wrapper interaction_range_wraper">
                  <b-progress
                    v-model="dailyViews"
                    max="150000"
                    class=""
                    variant="primary"
                    dir="ltr"
                  />
                  <b-form-input
                    type="range"
                    v-model="dailyViews"
                    min="0"
                    max="150000"
                    dir="rtl"
                    class="custom-range"
                    style="transform: rotateY(180deg)"
                    @change="monthlyIncomeCalc"
                  ></b-form-input>
                </div>
              </b-form-group>
              <b-form-group>
                <b-row class="justify-content-between align-items-center">
                  <b-col cols="6">
                    <span>{{ $t("Pages.Tools.Calculator.Label.CPM") }}</span>
                  </b-col>
                  <!-- /////range2 cpm label//// -->
                  <b-col cols="6" class="text-right">
                    <div>
                      <span style="font-size: 25px; color: #db2d36">{{
                        interactionAverage
                      }}</span>
                      %
                    </div>
                  </b-col>
                </b-row>
                <!-- /////range2 cpm//// -->
                <div class="progress-wrapper interaction_range_wraper">
                  <b-progress
                    v-model="interactionAverage"
                    max="100"
                    class=""
                    variant="primary"
                    dir="ltr"
                  />
                  <b-form-input
                    type="range"
                    v-model="interactionAverage"
                    min="0"
                    max="100"
                    dir="rtl"
                    style="transform: rotateY(180deg)"
                    @change="monthlyIncomeCalc"
                    class="custom-range"
                  >
                  </b-form-input>
                </div>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col md="6" class="mb-md-0 mb-4">
            <div class="text-center">
              <span style="font-size: 25px; color: #db2d36">{{
                dailyIncomes
              }}</span>
              <p style="color: white; font-size: 13px">
                {{ $t("Pages.Tools.Calculator.Label.income_day") }}
              </p>
            </div>
            <div class="text-center">
              <span style="font-size: 25px; color: #db2d36"
                >{{ monthlyIncomes }}
                {{ $t("Pages.Tools.Calculator.Label.Doller") }}</span
              >
              <p style="color: white; font-size: 13px">
                {{ $t("Pages.Tools.Calculator.Label.income_month") }}
              </p>
            </div>
            <div class="text-center">
              <span style="font-size: 25px; color: #db2d36"
                >{{ yearlyIncomes }}
                {{ $t("Pages.Tools.Calculator.Label.Doller") }}</span
              >
              <p style="color: white; font-size: 13px">
                {{ $t("Pages.Tools.Calculator.Label.income_year") }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-row class="align-items-center">
        <b-col lg="4" md="6">
          <b-img :src="collapsImage"></b-img>
        </b-col>
        <b-col lg="8" md="6">
          <app-collapse>
            <app-collapse-item
              v-for="(collap, index) in collaps"
              :key="index"
              :title="collap.title"
            >
              <span v-html="collap.body"></span>
              <b-row>
                <b-col class="text-right">
                  <b-link
                    :href="collap.link"
                    class="btn btn-info"
                    target="_blank"
                    >{{ $t("Pages.Tools.Calculator.Title.read_more") }}</b-link
                  >
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BCard,
  BImg,
  BButton,
  BLink,
  BFormGroup,
  BProgress,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BForm,
    BCard,
    BImg,
    BButton,
    BLink,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BProgress,
  },
  data() {
    return {
      collaps: [
        {
          title: this.$t("Pages.Tools.Calculator.Collaps.Title.title_num1"),
          body: this.$t("Pages.Tools.Calculator.Collaps.Body.body_num1"),
          link: "https://master.tube/faq/",
        },
        {
          title: this.$t("Pages.Tools.Calculator.Collaps.Title.title_num2"),
          body: this.$t("Pages.Tools.Calculator.Collaps.Body.body_num2"),
          link: "https://master.tube/faq/",
        },
        {
          title: this.$t("Pages.Tools.Calculator.Collaps.Title.title_num3"),
          body: this.$t("Pages.Tools.Calculator.Collaps.Body.body_num3"),
          link: "https://master.tube/faq/",
        },
        {
          title: this.$t("Pages.Tools.Calculator.Collaps.Title.title_num4"),
          body: this.$t("Pages.Tools.Calculator.Collaps.Body.body_num4"),
          link: "https://master.tube/faq/",
        },
        {
          title: this.$t("Pages.Tools.Calculator.Collaps.Title.title_num5"),
          body: this.$t("Pages.Tools.Calculator.Collaps.Body.body_num5"),
          link: "https://master.tube/faq/",
        },
      ],
      collapsImage: require("@/assets/images/assistant/review-request-man.svg"),
      dailyViews: 0,
      interactionAverage: 0,
      dailyIncomes: 0,
      monthlyIncomes: 0,
      yearlyIncomes: 0,
    };
  },
  computed: {
    fillBarColor() {
      // Define a range (e.g., 0 to 50) where the input bar should be red
      const redRangeStart = 0;
      const redRangeEnd = 100;

      // Define the red color and the default color
      const redColor = "red";
      const defaultColor = "transparent";

      // Determine the fill color based on the value of interactionAverage
      return this.interactionAverage >= redRangeStart &&
        this.interactionAverage <= redRangeEnd
        ? redColor
        : defaultColor;
    },
  },
  methods: {
    monthlyIncomeCalc() {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });

      this.monthlyViews = parseInt(this.monthlyViews);
      this.interactionAverage = parseInt(this.interactionAverage);

      this.dailyIncomes = formatter.format(
        Math.floor((this.dailyViews * this.interactionAverage) / 100) * 1
      );
      this.monthlyIncomes = formatter.format(
        Math.floor((this.dailyViews * this.interactionAverage) / 100) * 30
      );
      this.yearlyIncomes = formatter.format(
        Math.floor((this.dailyViews * this.interactionAverage) / 100) * 365
      );
    },
  },
};
</script>

<style>
.interaction_range_wraper {
  position: relative;
}
.custom-range {
  position: absolute;
  top: -4px;
  -webkit-appearance: none !important; /* Hide the default appearance */
  appearance: none !important;
  background: transparent !important; /* Transparent track background */
}
.custom-range::-webkit-slider-thumb {
  cursor: pointer; /* Display a pointer cursor on hover */
}
</style>
